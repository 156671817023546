//import Onboarding from "../../../pages/Onboarding/Onboarding";
import AccountLogin from "../../../pages/Onboarding/AccountLogin";
import PaymentCompletion from "../../../pages/Onboarding/PaymentComplete";
import BankInformation from "../../../pages/Onboarding/BankInformation";
import PracticeInformation from "../../../pages/Onboarding/PracticeInformation";
import AccountRegistration from "../../../pages/Onboarding/AccountSetup";
import SuccessOnboarding from "../../../pages/Onboarding/SuccessOnboarding";
import Page404 from "../../../pages/Onboarding/404page";
import PracticeQRCode from "../../../pages/Onboarding/PracticeQrCode";
import ServiceAggreement from "../../../pages/Onboarding/ServiceAgreement";
import Dashboard from "../../../pages/Dashboard/Dashboard";
import ClinicPractices from "../../../pages/Dashboard/ClinicPractices";
//import Dashboard from "../../../pages/Dashboard/Dashboard";
//import Home from "../../../pages/Home/Home";
//import PasswordReset from "../../../pages/PasswordReset/PasswordReset";
import FourZeroFour from "../../../pages/FourZeroFour/FourZeroFour";
import UpgradePlan from "../../../pages/Onboarding/UpdatePlan";

export const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    Component: AccountLogin,
    secured: false,
  },
  {
    path: "/login",
    name: "Login",
    exact: false,
    Component: AccountLogin,
  },
  {
    path: "/onboarding",
    name: "On-boarding",
    exact: true,
    Component: AccountRegistration,
  },
  {
    path: "/onboarding/practice-information",
    name: "On-boarding",
    exact: true,
    Component: PracticeInformation,
  },
  {
    path: "/onboarding/bank-information",
    name: "On-boarding",
    exact: true,
    Component: BankInformation,
  },
  {
    path: "/onboarding/payment-completion",
    name: "On-boarding",
    exact: true,
    Component: PaymentCompletion,
  },
  {
    path: "/onboarding/order-reference",
    name: "On-boarding",
    exact: true,
    Component: SuccessOnboarding,
  },
  {
    path: "/onboarding/service-agreement",
    name: "On-boarding",
    exact: true,
    Component: ServiceAggreement,
  },
  {
    path: "/practice-qr-code",
    name: "On-boarding",
    exact: true,
    Component: PracticeQRCode,
  },
  {
    path: "/dashboard/my-practices",
    name: "Dashboard",
    exact: true,
    Component: ClinicPractices,
  },
  {
    path: "/dashboard/upgrade-plan",
    name: "Dashboard",
    exact: true,
    Component: UpgradePlan,
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/:view",
    name: "Dashboard",
    exact: true,
    Component: Dashboard,
  },
  /*{
    path: "/dashboard/",
    name: "Dashboard",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/share-qr-code",
    name: "Dashboard",
    exact: true,
    Component: Dashboard,
  },*/
  /*{
    path: "/dashboard/pets-plan",
    name: "Pets Plan",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/create-claim",
    name: "Create Claim",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/claims-history",
    name: "Claims History",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/personal-delivery-details",
    name: "Personal & Delivery Details",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/change-password",
    name: "Change Password",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/edit-payment-details",
    name: "Edit payment details",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/membership-invoices",
    name: "Membership Invoices",
    exact: true,
    Component: Dashboard,
  },*/
  /*{
    path: "/password/reset",
    name: "PasswordReset",
    exact: true,
    Component: PasswordReset,
    secured: false,
  },*/
  {
    path: "/",
    name: "Empty",
    exact: false,
    Component: Page404,
  },
];
