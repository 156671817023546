import "./Onboarding.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useFormik } from "formik";
import { Row, Modal } from "../../components/layout";
import {
  isNotEmpty,
  isMobile,
  isEmail,
  isAffiliateCodeExists,
} from "../../utils/formik-validate";
import { GetAllAffiliateCodes } from "../../api/affiliate";
import { stripCountry } from "../../utils/mobile-number";
import {
  PhoneInput,
  TextInput,
  ImageInput,
  DropdownInput,
} from "../../components/input";
import AddressInput from "../../components/input/AddressInput/AddressInput";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import { actionCreators as commonActionCreators } from "../../store/Common";
import { Button } from "../../components/common";
import history from "../../utils/history";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  arrowRightIcon,
} from "./Module";

const imageBaseUrl: any = `${process.env.REACT_APP_BACKEND_API}${process.env?.REACT_APP_BACKEND_IMAGE_ENDPOINT}`;
const PracticeInformation: React.FC = () => {
  const location = useLocation();
  const {
    fetchUserData,
    updatePracticeInformation,
    fetchPractices,
    addNewPractice,
  } = bindActionCreators(userActionCreators, useDispatch());
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const [affiliateCodes, setAffiliateCodes] = useState<any>([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const user = useSelector((state: ApplicationState) => state.user);
  const common = useSelector((state: ApplicationState) => state.common);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pageErrors, setPageErrors] = useState<any>([]);
  const [countryCode, setCountryCode] = useState("+61");
  const [userAddress, setUserAddress] = useState({
    practice_name: "",
    coaching_group: "YPPA",
    website: "",
    affiliateCode: "",
    image: undefined,
    email: "",
    contact_person: "",
    contact_email: "",
    contact_firstname: "",
    contact_lastname: "",
    contact_jobtitle: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    province: "",
    postcode: "",
  });
  const [coachingGroup, setCoachingGroup] = useState([
    { label: "No", value: "YPPA" },
    { label: "Accelerate Mastermind Group (AMG)", value: "AMG" },
    { label: "Crampton Consulting", value: "Crampton Consulting" },
    { label: "Lincoln Institute", value: "Lincoln" },
    { label: "Vetrr", value: "Vetrr" },
    { label: "Other", value: "YPPA" },
  ]);
  useEffect(() => {
    (async () => {
      console.log(`practice information reload`);
      await fetchUserData();
    })();
  }, [location]);
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding/");
    //prettier-ignore
    (async () => {
      console.log({ c: common?.actionType });
      if (common?.actionType == "add-new-practice") {
        setPreviewUrl("");
        setUserAddress({
          practice_name: "",
          coaching_group: "YPPA",
          website: "",
          affiliateCode: "",
          image: undefined,
          email: "",
          contact_person: "",
          contact_email: "",
          contact_firstname: "",
          contact_lastname: "",
          contact_jobtitle: "",
          phone: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          country: "",
          province: "",
          postcode: "",
        });
      } else {
        setPreviewUrl(imageBaseUrl + user?.image_url);
        setUserAddress({
          ...userAddress,
          email: user?.email ? user?.email : "",
          practice_name: user?.practice_name ? user?.practice_name : "",
          website: user?.website ? user?.website : "",
          affiliateCode: user?.affiliateCode ? user?.affiliateCode : "",
          phone: user?.phone ? user?.phone : "",
          address1: user?.address?.address1 ? user?.address?.address1 : "",
          address2: user?.address?.address2 ? user?.address?.address2 : "",
          city: user?.address?.city ? user?.address?.city : "",
          state: user?.address?.state ? user?.address?.state : "",
          country: user?.address?.country ? user?.address?.country : "",
          province: user?.address?.province ? user?.address?.province : "",
          postcode: user?.address?.postcode ? user?.address?.postcode : "",
          contact_email: user?.contact_email ? user?.contact_email : "",
          contact_person: user?.contact_person ? user?.contact_person : "",
          contact_firstname: user?.contact_firstname ? user?.contact_firstname : "",
          contact_lastname: user?.contact_lastname ? user?.contact_lastname : "",
          contact_jobtitle: user?.contact_jobtitle ? user?.contact_jobtitle : "",
        });
      }
      const code = user?.address?.country == "Australia" ? "+61" : "+64";
      console.log({ user, imageBaseUrl });
      setCountryCode(code);
      await GetAllAffiliateCodes().then((r: any) => {
        if (!r || !r?.data || (r?.data && r?.data?.length == 0)) return;
        const data = r.data
          ?.reduce((g: any, c: any) => {
            const { affiliate_code: ac, affiliate_codes: acs } = c;
            if (ac) g.push(ac);
            if (acs) g.push(acs);
            return g;
          }, [])
          ?.filter((f: any) => {
            return f != user?.affiliateCode;
          });
        setAffiliateCodes(data);
      });
      //prettier-ignore end
    })();
  }, [isAuthenticated, user?.practice_id, common?.actionType]);
  const BasicInformation = useFormik({
    initialValues: userAddress,
    onSubmit: async (values) => {
      //return console.log({ test, values });
      setIsLoading(true);
      const data = {
        email: values.email,
        image: values.image,
        contact_email: values.contact_email,
        contact_jobtitle: values.contact_jobtitle,
        contact_person: `${values.contact_firstname} ${values.contact_lastname}`,
        contact_firstname: values.contact_firstname,
        contact_lastname: values.contact_lastname,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        postcode: values.postcode,
        practice_name: values.practice_name,
        coaching_group: values.coaching_group,
        affiliate_code: values.affiliateCode,
        website: values.website,
        phone: values.phone,
      };
      if (common?.actionType != "add-new-practice")
        await updatePracticeInformation(data);
      else {
        await addNewPractice(data);
        await fetchPractices();
      }
      setIsLoading(false);
      await changeActionType(undefined);
      return history.push("/onboarding/bank-information");
    },
    validate: async ({
      practice_name,
      affiliateCode,
      email,
      contact_email,
      contact_jobtitle,
      contact_firstname,
      contact_lastname,
      phone,
      address1,
      city,
      state,
      country,
      postcode,
    }) => {
      const errors = {
        ...isNotEmpty(practice_name, "practice_name"),
        ...isNotEmpty(affiliateCode, "affiliateCode"),
        ...isAffiliateCodeExists(
          affiliateCode,
          affiliateCodes,
          "affiliateCode"
        ),
        //...isNotEmpty(website, "website"),
        ...isNotEmpty(email, "email"),
        ...isEmail(email, "email"),
        ...isNotEmpty(address1, "address1"),
        ...isNotEmpty(city, "city"),
        ...isNotEmpty(state, "state"),
        ...isNotEmpty(country, "country"),
        ...isNotEmpty(postcode, "postcode"),
        ...isNotEmpty(contact_firstname, "contact_firstname"),
        ...isNotEmpty(contact_lastname, "contact_lastname"),
        ...isNotEmpty(contact_email, "contact_email"),
        ...isNotEmpty(contact_jobtitle, "contact_jobtitle"),
        ...isEmail(contact_email, "contact_email"),
        ...isNotEmpty(phone, "phone"),
        //...isMobile(`(${countryCode})${phone}`, "phone"),
      };
      setPageErrors(Object.values(errors));
      //console.log({ errors });
      return errors;
    },
    enableReinitialize: true,
  });
  const ClinicInformation = () => {
    return (
      <div className="content">
        <OnboardingHeader step={1} stepTotal={3} />
        <div className="content-box">
          <OnboardingMenus active="practice-information" />
          <h2 className="title-2">Welcome to VetXtend</h2>
          {user?.status == "validation" && (
            <p>
              You will shortly receive an email to your nominated email address,
              please click the link contained in the email to verify your
              address.
            </p>
          )}
          <Row>
            <p>
              <strong>Upload a copy of your practice logo</strong>
              {""} (This can also be done at a later stage)
            </p>
          </Row>
          <div className="clinic-logo" style={{ display: "flex" }}>
            <div>
              <img className="image-preview" src={previewUrl} />
            </div>
            <div className="image-uploader">
              <ImageInput
                label="Upload logo"
                onClickSave={(image, previewImgUrl) => {
                  console.log({ image });
                  BasicInformation.setFieldValue("image", image);
                  setPreviewUrl(previewImgUrl);
                }}
              />
            </div>
            <p>
              We are asking for your practice logo <br />
              so we can personalize some promotional <br />
              material for your practice. You will be able
              <br />
              to use these materials to promote our
              <br />
              partnership to your clients.
            </p>
          </div>
          <Row>
            <TextInput
              label="Practice Name"
              value={BasicInformation.values.practice_name}
              error={BasicInformation.errors.practice_name}
              touched={BasicInformation.touched.practice_name}
              onChange={BasicInformation.handleChange("practice_name")}
              onBlur={() => {
                const { values } = BasicInformation;
                const affiliateCode = values.practice_name
                  .replaceAll(/[^a-zA-z0-9-]/g, "")
                  .toLocaleLowerCase();
                if (userAddress?.affiliateCode) return;
                console.log({ values, affiliateCode });
                BasicInformation.setValues({
                  ...values,
                  affiliateCode,
                });
              }}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Practice URL"
              notes="(website address)"
              value={BasicInformation.values.website}
              error={BasicInformation.errors.website}
              touched={BasicInformation.touched.website}
              onChange={BasicInformation.handleChange("website")}
            />
          </Row>
          <Row>
            <TextInput
              disabled={userAddress?.affiliateCode ? true : false}
              label="Practice Affiliate Code"
              notes="(Once saved you can no longer edit this!)"
              notesBottom="This code is used to track your customer purchases and should be easy to remember and unique."
              value={BasicInformation.values.affiliateCode}
              error={BasicInformation.errors.affiliateCode}
              touched={BasicInformation.touched.affiliateCode}
              onChange={BasicInformation.handleChange("affiliateCode")}
              onBlur={() => {
                const { values } = BasicInformation;
                const affiliateCode = values.affiliateCode
                  .replaceAll(/[^a-zA-z0-9-]/g, "")
                  .toLocaleLowerCase();
                console.log({ values, affiliateCode });
                BasicInformation.setValues({
                  ...values,
                  affiliateCode,
                });
              }}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Practice Email Address"
              value={BasicInformation.values.email}
              error={BasicInformation.errors.email}
              touched={BasicInformation.touched.email}
              onChange={BasicInformation.handleChange("email")}
              required
            />
          </Row>
          <Row>
            <DropdownInput
              label="Is your practice part of a coaching group?"
              required={true}
              options={coachingGroup}
              error={BasicInformation.errors.coaching_group}
              touched={BasicInformation.touched.coaching_group}
              onChange={BasicInformation.handleChange("coaching_group")}
              value={BasicInformation.values.coaching_group}
            />
          </Row>
          <Row>
            <TextInput
              label="Phone Number"
              value={BasicInformation.values.phone}
              error={BasicInformation.errors.phone}
              touched={BasicInformation.touched.phone}
              onChange={BasicInformation.handleChange("phone")}
              required
            />
          </Row>
          <Row>
            <AddressInput form={BasicInformation} />
          </Row>
          <Row>
            <TextInput
              label="Contact First Name"
              value={BasicInformation.values.contact_firstname}
              error={BasicInformation.errors.contact_firstname}
              touched={BasicInformation.touched.contact_firstname}
              onChange={BasicInformation.handleChange("contact_firstname")}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Contact Last Name"
              value={BasicInformation.values.contact_lastname}
              error={BasicInformation.errors.contact_lastname}
              touched={BasicInformation.touched.contact_lastname}
              onChange={BasicInformation.handleChange("contact_lastname")}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Contact Email"
              value={BasicInformation.values.contact_email}
              error={BasicInformation.errors.contact_email}
              touched={BasicInformation.touched.contact_email}
              onChange={BasicInformation.handleChange("contact_email")}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Job Title"
              value={BasicInformation.values.contact_jobtitle}
              error={BasicInformation.errors.contact_jobtitle}
              touched={BasicInformation.touched.contact_jobtitle}
              onChange={BasicInformation.handleChange("contact_jobtitle")}
              required
            />
          </Row>
        </div>
        <p className="bottom-action text-center">
          <img
            src={arrowLeftIcon}
            onClick={() => history.push("/onboarding/")}
          />
          <Button
            label="Next"
            isDisabled={isLoading}
            customClass="button btn btn-dark-blue"
            onClick={() => {
              console.log({ pageErrors });
              if (pageErrors && pageErrors.length > 0)
                toast.error(`Please fulfill the requirements above`);
              BasicInformation.handleSubmit();
            }}
          />
          <img
            src={arrowRightIcon}
            onClick={() => history.push("/onboarding/bank-information")}
          />
        </p>
        <p style={{ padding: "20px", textAlign: "center", fontSize: "22px" }}>
          Need to subscribe more than one practice? You’ll have the option
          <br />
          to add more practices once your first subscription is finalised.
        </p>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {ClinicInformation()}
    </div>
  );
};

export default PracticeInformation;
