import React, { FC, useEffect, useState } from "react";
import axiosConfig from "../../../api/axios";
import { Page, pdfjs, Document } from "react-pdf";
import { Button } from "..";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const invoiceEndpoint = process.env.REACT_APP_BACKEND_INVOICE_ENDPOINT;
const backendEndpoint = process.env.REACT_APP_BACKEND_API;

interface InvoiceProps {
  /**
   * The claim number of the invoice.
   */
  claimId?: string;
  /**
   * Whether the claims is of PDF format.
   */
  isPdf?: boolean;

  rebateFile?: string;
}

/**
 * Component used for displaying a claim invoice image/pdf.
 * Recieves a claim id so that it can fetch its own image/pdf.
 */
export const Invoice: FC<InvoiceProps> = ({
  claimId,
  isPdf = false,
  rebateFile,
}) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFile("");
    if (claimId && invoiceEndpoint && backendEndpoint) {
      (async () => {
        setIsLoading(true);
        const photo = await axiosConfig.get(
          backendEndpoint + invoiceEndpoint + claimId,
          {
            responseType: "arraybuffer",
          }
        );
        if (photo && photo.data) {
          setFile(Buffer.from(photo.data, "binary").toString("base64"));
          setIsLoading(false);
        } else {
          setFile("");
          setIsLoading(false);
        }
      })();
    }
    if (rebateFile) {
      (async () => {
        setIsLoading(true);
        const photo = await axiosConfig.get(
          backendEndpoint + "/assets/rebates?name=" + rebateFile,
          {
            responseType: "arraybuffer",
          }
        );
        if (photo && photo.data) {
          setFile(Buffer.from(photo.data, "binary").toString("base64"));
          setIsLoading(false);
        } else {
          setFile("");
          setIsLoading(false);
        }
      })();
    }
  }, [claimId]);

  interface NumPagesProp {
    numPages: any;
  }

  function onDocumentLoadSuccess(numPagesProp: NumPagesProp): void {
    setNumPages(numPagesProp.numPages);
    setPageNumber(1);
  }

  return isPdf ? (
    <div>
      <Document
        file={"data:application/pdf;base64," + file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
      </Document>
      {file && numPages > 1 && (
        <p>
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <Button
            label="Back"
            onClick={() => {
              if (pageNumber <= numPages && pageNumber > 1)
                setPageNumber(pageNumber - 1);
            }}
          />

          <Button
            label="Next"
            onClick={() => {
              if (pageNumber !== numPages) setPageNumber(pageNumber + 1);
            }}
          />
        </p>
      )}
    </div>
  ) : (
    <>
      {!isLoading ? (
        <>
          {file ? (
            <img
              src={"data:image;base64," + file}
              alt="Invoice"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                paddingRight: "5%",
              }}
            />
          ) : (
            <h5 style={{ textAlign: "center" }}>File Not Found</h5>
          )}
        </>
      ) : (
        <h5 style={{ textAlign: "center" }}>Loading...</h5>
      )}
    </>
  );
};
