import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Address } from "../types/address";
import axiosConfig from "./axios";
const API = process.env.REACT_APP_BACKEND_API;

export async function getCustomer() {
  const result = await axiosConfig.get(`${API}/chargebee/get-customer`);
  return result;
}

export async function subscribeAPlan(
  plan: string,
  practice_id: string,
  addon?: string,
  extras?: any,
  coupon?: string
): Promise<AxiosResponse> {
  const subscription = await axiosConfig.post(`${API}/chargebee/subscription`, {
    plan,
    practice_id,
    addon,
    extras,
    coupon,
  });
  return subscription;
}

export async function upgradeAPlan(
  plan: string,
  subscription_id?: string,
  subscription_addon_id?: string,
  practice_id?: string,
  coupon?: string
): Promise<AxiosResponse> {
  const subscription = await axiosConfig.post(
    `${API}/chargebee/subscription-upgrade`,
    {
      plan,
      subscription_id,
      subscription_addon_id,
      practice_id,
      coupon,
    }
  );
  return subscription;
}

//TO BE OBSOLETE - because it will automatically generated upon paying
export async function createCustomer(): Promise<AxiosResponse | undefined> {
  return axiosConfig
    .post(`${API}/chargebee/create-customer`)
    .then((response) => {
      console.log({ response });
      if (!response) return;
      const { data, status } = response;
      if (status !== 200) return undefined;
      return data;
    });
}

export async function updateBillingAddress(address: Address) {
  const result = await axiosConfig.post(
    `${API}/signup/chargebee/updateBillingAddress`,
    {
      address: address,
    }
  );
  return result;
}

export async function getPaymentMethods(): Promise<string | undefined> {
  const result = await axiosConfig.get(`${API}/chargebee/payment-methods`);
  if (result.status !== 200) {
    toast.error("Error getting URL for payments.");
    return undefined;
  }
  return result.data.url;
}

/*GETTING INVOICES BY CUSTOMER ID */
export async function getCustomersInvoices(
  customer_id: string,
  country: string
): Promise<any> {
  return axiosConfig
    .post(`${API}/chargebee/list-invoices`, {
      customer_id,
      country,
    })
    .then((response) => {
      if (!response) return;
      const { status, data } = response;
      if (status !== 200) {
        return toast.error("Error getting chargebee invoices.");
      }
      return data;
    });
}

/*GETTING CHARGEBEE INVOICE BY ID */
export async function getInvoice(
  invoice_id: string,
  country: string
): Promise<any> {
  return axiosConfig
    .post(`${API}/chargebee/get-invoice`, {
      invoice_id,
      country,
    })
    .then((response) => {
      if (!response) return;
      const { status, data } = response;
      if (status !== 200) {
        return toast.error(`
          Error getting chargebee invoice id - ${invoice_id}.`);
      }
      return data;
    });
}

/* GETTING SUBSCRIPTIONS BY CUSTOMER ID */
export async function getCustomersSubscription(
  customer_id: string,
  country: string
): Promise<any> {
  return axiosConfig
    .post(`${API}/chargebee/list-subscriptions`, {
      customer_id,
      country,
    })
    .then((response) => {
      if (!response) return;
      const { status, data } = response;
      if (status !== 200) {
        return toast.error("Error getting chargebee subscriptions.");
      }
      return data;
    });
}

/*GETTING CUSTOMER CHARGEBEE CARD */
export async function getCustomerDetail(
  email: string,
  country: string
): Promise<any> {
  return axiosConfig
    .post(`${API}/chargebee/get-customer-detail`, {
      email,
      country,
    })
    .then((response) => {
      if (!response) return;
      const { status, data } = response;
      if (status !== 200) {
        return toast.error("Error chargebee customer details.");
      }
      return data;
    });
}

export async function checkCoupon(email: string, couponId: string) {
  const couponResponse = await axiosConfig.post(
    `${API}/signup/chargebee/checkCoupon`,
    { email, couponId }
  );
  if (couponResponse.status === 200) {
    return couponResponse.data;
  } else {
    return { couponId, valid: false, reason: "Error checking coupon." };
  }
}

/*export async function estimateSubscriptions(
  data: { petId: string; planId: string; planType: string }[],
  coupon: string,
  address: Address
): Promise<AxiosResponse> {
  const filteredData = data.filter((pet) => pet.planId !== "none");
  const filteredDataWithCouponApplied = filteredData.map((d) => ({
    ...d,
    coupon,
    address: address,
  }));
  const result = await axiosConfig.post(
    `${API}/signup/chargebee/estimateSubscription`,
    filteredDataWithCouponApplied
  );
  return result;
}*/

export async function applyCouponDiscount(data: any): Promise<any> {
  //return console.log({ data });
  const result = await axiosConfig.post(
    `${API}/chargebee/subscription-estimate`,
    data
  );
  return result;
}

export async function createSubscriptions(
  data: { petId: string; planId: string; planType: string }[],
  coupon: string,
  address: Address,
  affiliateCode = "",
  affiliateName = "",
  total: number
): Promise<AxiosResponse> {
  const filteredData = data.filter((pet) => pet.planId !== "none");
  const filteredDataWithCouponApplied = filteredData.map((existingData) => ({
    ...existingData,
    ...(affiliateCode ? { affiliateCode } : {}),
    ...(affiliateName ? { affiliateName } : {}),
    coupon,
    address,
    total,
  }));
  console.log(filteredDataWithCouponApplied);
  const result = await axiosConfig.post(
    `${API}/signup/chargebee/createSubscription`,
    filteredDataWithCouponApplied
  );
  return result;
}

export async function getUpdatedSubscription(petId: string) {
  const result = await axiosConfig.post(
    `${API}/signup/chargebee/getUpdatedSubscription`,
    {
      petId: petId,
    }
  );
  return result;
}
