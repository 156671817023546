import axios from "../api/axios";

/*export const GetCommissionReport = async ({
  practice_key,
  year,
  month,
}: any): Promise<any> => {
  return axios.post<any>("/reporting/get-commission-report", {
    practice_key,
    year,
    month,
  });
};*/

export const GetOnlineSales = async ({
  practice_key,
  practice_id,
}: any): Promise<any> => {
  return axios.post<any>("/reporting/get-onlinesales-report", {
    practice_key,
    practice_id,
  });
};

export const GetWellnessPlan = async ({
  practice_key,
  practice_id,
}: any): Promise<any> => {
  return axios.post<any>("/reporting/get-wellnessplan-report", {
    practice_key,
    practice_id,
  });
};

export const GetRebateStatement = async ({
  practice_key,
  practice_id,
}: any): Promise<any> => {
  return axios.post<any>("/reporting/get-rebatestatement-report", {
    practice_key,
    practice_id,
  });
};
