import { MenuItem } from "./Footer";
const SITEURL =
  window.location.host != "app.vetxtend.co.nz"
    ? "https://vetxtend.com.au"
    : "https://vetxtend.co.nz";
/**
 * Menu links to map over when rending menus.
 */
export const menuLinks: { [key: string]: MenuItem[] } = {
  menu1: [
    {
      label: "Practice Details",
      href: `/dashboard/my-practices`,
    },
  ],
  menu2: [
    {
      label: "Rebate Statements",
      href: `/dashboard/rebate-statements`,
    },
    {
      label: "Commission Summary",
      href: `/dashboard/commission-summary`,
    },
    {
      label: "Online Sales",
      href: `/dashboard/sales-snapshot`,
    },
    {
      label: "Thriving Pets Members",
      href: `/dashboard/wellness-plan`,
    },
    {
      label: "Practice QR Code",
      href: `/dashboard/practice-details`,
    },
  ],
  menu3: [
    {
      label: "PetPA Website",
      href: `https://yourpetpa.com.au`,
      external: true,
    },
    {
      label: "Thriving Pets Website",
      href: `https://thrivingpets.com.au`,
      external: true,
    },
  ],
};
