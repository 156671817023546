import "./Dashboard.scss";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import history from "../../utils/history";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApplicationState } from "../../store";
import { SendQRcodeViaEmail } from "../../api/account";

import thrivingPetsLogoDarkReverse from "../../assets/icons/thrivingpets-logo-dark-reverse.svg";
import headsetIcon from "../../assets/icons/headset.svg";
import dogCatIcon from "../../assets/icons/dog-and-cat.svg";
import arrowRightIcon from "../../assets/icons/arrow-right.svg";
import catDogd3Icon from "../../assets/icons/dog-and-cat-d3.svg";
import chatIcon from "../../assets/icons/chat.svg";
import checkIcon from "../../assets/icons/check.svg";
import minusIcon from "../../assets/icons/minus.svg";
import truckIcon from "../../assets/images/icons/truck.svg";
import arrowLeftIcon from "../../assets/icons/arrow-left.svg";
import hospitalIcon from "../../assets/icons/hospital.svg";
import injectionIcon from "../../assets/icons/injection.svg";
import stethoscopeIcon from "../../assets/icons/stethoscope.svg";
import toothbrushIcon from "../../assets/icons/toothbrush.svg";
import desexedIcon from "../../assets/icons/desexed.svg";
import storeIcon from "../../assets/icons/store.svg";
import calculatorIcon from "../../assets/icons/calculator.svg";
import faqIcon from "../../assets/icons/faq.svg";
import dogAndCat from "../../assets/icons/dog-and-cat.svg";
import trashIcon from "../../assets/icons/trash.svg";
import editIcon from "../../assets/icons/edit.svg";
import listIcon from "../../assets/icons/list.svg";
import claimIcon from "../../assets/icons/claim.svg";
import claimHistoryIcon from "../../assets/icons/claim-history.svg";
import membershipIcon from "../../assets/icons/membership.svg";
import myDetailsIcon from "../../assets/icons/my details.svg";
import homeIcon from "../../assets/icons/home.svg";
import iconDownload from "../../assets/vetxtend/icons/icon-download-white.png";
import iconEnvelope from "../../assets/vetxtend/icons/icon-envelope-white.png";
import iconLink from "../../assets/vetxtend/icons/icon-link-white.png";
import vetXtendLogoLight from "../../assets/images/vetxtend-logo-light.svg";

const ModuleQRcode: React.VFC<any> = () => {
  const user = useSelector((state: ApplicationState) => state.user);
  const [qrCode, setQrCode] = useState<string>(`
    https://yourpetpa.com.au/?ref=${user?.affiliateCode}`);
  useEffect(() => {}, []);
  return (
    <div className="text-center">
      <h2 className="title-2" style={{ textAlign: "center", display: "none" }}>
        Your practice's unique QR code
      </h2>
      <div className="download-qrcode">
        <p>
          Share this QR code or unique link with your clients and start earning
          commission on all sales with our online store and pharmacy
          <br />
          <br />
        </p>
        <div
          className="wrapper"
          style={{
            margin: "0px 0 20px",
            display: "flex",
            justifyContent: "center",
            columnGap: "20px",
          }}
        >
          <div id="qr-code" style={{ textAlign: "right" }}>
            <QRCode value={qrCode} viewBox={`0 0 256 256`} />
          </div>
          <div className="download-bottons">
            <span
              className="btn-wrapper"
              onClick={() => {
                const QRimg = document.getElementById("qr-code");
                console.log({ img: QRimg?.innerHTML });
                if (!QRimg) return;
                const b64dc = btoa(
                  unescape(encodeURIComponent(QRimg.innerHTML))
                );
                const a = document.createElement("a");
                const e = new MouseEvent("click");
                a.download = "vetxtend-qrcode.svg";
                a.href = "data:image/svg+xml;base64," + b64dc;
                a.dispatchEvent(e);
              }}
            >
              <span className="img-wrap">
                <img src={iconDownload} />
              </span>
              Download QR Code
            </span>
            <span
              className="btn-wrapper"
              onClick={() => {
                const QRimg = document.getElementById("qr-code");
                console.log({ img: QRimg?.innerHTML });
                if (!QRimg) return;
                const b64dc = btoa(
                  unescape(encodeURIComponent(QRimg.innerHTML))
                );
                return SendQRcodeViaEmail(
                  "data:image/svg+xml;base64," + b64dc,
                  qrCode
                ).then((r: any) => {
                  toast.success(`Referral link sent!`);
                });
              }}
            >
              <span className="img-wrap">
                <img
                  src={iconEnvelope}
                  style={{
                    width: "20px",
                    height: "auto",
                    margin: "6px auto auto 3px",
                  }}
                />
              </span>
              Send QR Code Via Email
            </span>
            <span
              className="btn-wrapper link"
              onClick={() => {
                return (window.location.href = qrCode);
              }}
            >
              <span className="img-wrap">
                <img
                  src={iconLink}
                  style={{
                    width: "20px",
                    height: "auto",
                    margin: "4px auto auto 2px",
                  }}
                />
              </span>
              {qrCode}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const ModuleEmptyData: React.VFC<any> = () => {
  const user = useSelector((state: ApplicationState) => state.user);
  const [qrCode, setQrCode] = useState<string>(`
    https://yourpetpa.com.au/?ref=${user?.affiliateCode}`);
  useEffect(() => {}, []);
  return (
    <div>
      <p>
        Looks like you don't have any online sales yet.
        <br />
        Would you like to learn more about how this works for
        <br />
        both you and your clients?
      </p>
      <p>
        <a
          href="https://calendly.com/psadler-yourpetpa/learn-more-about-partnership-program-and-rebates"
          className="btn btn-dark-blue"
          target="_blank"
          style={{ display: "inline-block" }}
        >
          Book a chat with us
        </a>
      </p>
    </div>
  );
};
export {
  ModuleQRcode,
  ModuleEmptyData,
  headsetIcon,
  catDogd3Icon,
  arrowRightIcon,
  dogCatIcon,
  chatIcon,
  checkIcon,
  minusIcon,
  truckIcon,
  arrowLeftIcon,
  hospitalIcon,
  injectionIcon,
  stethoscopeIcon,
  toothbrushIcon,
  desexedIcon,
  storeIcon,
  calculatorIcon,
  faqIcon,
  dogAndCat,
  trashIcon,
  editIcon,
  listIcon,
  claimIcon,
  claimHistoryIcon,
  membershipIcon,
  myDetailsIcon,
  homeIcon,
  iconDownload,
  iconLink,
  vetXtendLogoLight,
  thrivingPetsLogoDarkReverse,
};
