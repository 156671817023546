import "./Onboarding.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { actionCreators as userActionCreators } from "../../store/User";
import { useFormik } from "formik";
import { Row, Modal } from "../../components/layout";
import {
  isNotEmpty,
  isValidBSB,
  isValidAccountNumber,
  isValidAccountNumberNZ,
} from "../../utils/formik-validate";
import { TextInput, DropdownInput } from "../../components/input";
import { Button } from "../../components/common";
import { ApplicationState } from "../../store";
import history from "../../utils/history";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  arrowRightIcon,
  ModalAddingPractice,
} from "./Module";

const BankInformation: React.FC = () => {
  const location = useLocation();
  const { fetchUserData, updatePracticeBankAndSystem } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pms, setPms] = useState([
    { label: "Ascend", value: "Ascend" },
    { label: "Cornerstone", value: "Cornerstone" },
    { label: "Ezyvet", value: "Ezyvet" },
    { label: "Neo", value: "Neo" },
    { label: "OpenVPMS", value: "OpenVPMS" },
    { label: "Panacea", value: "Panacea" },
    { label: "Petbooqz", value: "Petbooqz" },
    { label: "RxWorks", value: "RxWorks" },
    { label: "Vetlink", value: "Vetlink" },
    { label: "Vision", value: "Vision" },
    { label: "Other", value: "Other" },
  ]);
  const [financeSystem, setFinanceSystem] = useState([
    { label: "MYOB", value: "MYOB" },
    { label: "Xero", value: "Xero" },
    { label: "Vision", value: "Vision" },
    { label: "Quickbooks", value: "Quickbooks" },
    { label: "Reckon", value: "Reckon" },
    { label: "Reckon Hosted", value: "Reckon Hosted" },
    { label: "Other", value: "Other" },
  ]);
  const [userBankInformation, setUserBankInformation] = useState({
    pms_system: "",
    finance_system: "",
    bank_account_name: "",
    bank_account_bsb: "",
    bank_account_number: "",
  });
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding/");
    if (!user?.practice_name || user?.practice_name == "")
      return history.push("/onboarding/practice-information");
    //console.log({ user });
    /*let { finance_system: fs } = userBankInformation;
    fs = financeSystem.find((f) => f.value == fs) ? "xx" : "";
    setUserBankInformation({});*/
  }, [isAuthenticated, location]);
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding/");
    (async () => {
      console.log(`user reloaded`);
      await fetchUserData();
      setUserBankInformation({
        pms_system: pms.find((f) => f.value == user?.pms_system)
          ? String(user?.pms_system)
          : "",
        finance_system: financeSystem.find(
          (f) => f.value == user?.finance_system
        )
          ? String(user?.finance_system)
          : "",
        bank_account_name: user?.bank_account_name
          ? user?.bank_account_name
          : "",
        bank_account_bsb: user?.bank_account_bsb ? user?.bank_account_bsb : "",
        bank_account_number: user?.bank_account_number
          ? user?.bank_account_number
          : "",
      });
    })();
  }, [user?.practice_id]);
  const BankSystemInformation = useFormik({
    initialValues: userBankInformation,
    onSubmit: async (values) => {
      setIsLoading(true);
      const {
        pms_system: psystem,
        finance_system: fsystem,
        bank_account_name: acn,
        bank_account_bsb: bsb,
        bank_account_number: acno,
      } = values;
      const emailAdmin =
        user?.subscription_id &&
        (acno != user?.bank_account_number || acn != user?.bank_account_name)
          ? true
          : false;
      const data = {
        pms_system: psystem,
        finance_system: fsystem,
        bank_account_name: acn,
        bank_account_bsb: bsb,
        bank_account_number: acno,
        email_admin: emailAdmin,
      };
      console.log({ user, data, emailAdmin });
      await updatePracticeBankAndSystem(data);
      setIsLoading(false);
      return history.push("/onboarding/payment-completion");
    },
    validate: async ({
      pms_system,
      finance_system,
      bank_account_name,
      bank_account_bsb,
      bank_account_number,
    }) => {
      if (user?.address?.country == "New Zealand") {
        return {
          ...isNotEmpty(pms_system, "pms_system"),
          ...isNotEmpty(finance_system, "finance_system"),
          ...isNotEmpty(bank_account_name, "bank_account_name"),
          ...isNotEmpty(bank_account_number, "bank_account_number"),
          ...isValidAccountNumberNZ(bank_account_number, "bank_account_number"),
        };
      }
      return {
        ...isNotEmpty(pms_system, "pms_system"),
        ...isNotEmpty(finance_system, "finance_system"),
        ...isNotEmpty(bank_account_name, "bank_account_name"),
        ...isNotEmpty(bank_account_bsb, "bank_account_bsb"),
        ...isValidBSB(bank_account_bsb, "bank_account_bsb"),
        ...isNotEmpty(bank_account_number, "bank_account_number"),
        ...isValidAccountNumber(bank_account_number, "bank_account_number"),
      };
    },
    enableReinitialize: true,
  });
  const Information = () => {
    return (
      <div className="content">
        <OnboardingHeader step={2} stepTotal={3} />
        <div className="content-box">
          <OnboardingMenus active="bank-information" />
          <h2 className="title-2">Personal Bank Account Information</h2>
          <p>
            We are asking for your bank account information so we can &nbsp;
            <strong>
              pay your rebates on the 15th of each calendar month.
            </strong>
          </p>
          <Row>
            <TextInput
              label="Account Name"
              value={BankSystemInformation.values.bank_account_name}
              error={BankSystemInformation.errors.bank_account_name}
              touched={BankSystemInformation.touched.bank_account_name}
              onChange={BankSystemInformation.handleChange("bank_account_name")}
              required
            />
          </Row>
          <Row>
            {user?.address?.country != "New Zealand" && (
              <TextInput
                label="BSB"
                value={BankSystemInformation.values.bank_account_bsb}
                error={BankSystemInformation.errors.bank_account_bsb}
                touched={BankSystemInformation.touched.bank_account_bsb}
                onChange={BankSystemInformation.handleChange(
                  "bank_account_bsb"
                )}
                required
              />
            )}
            <TextInput
              label="Account Number"
              value={BankSystemInformation.values.bank_account_number}
              error={BankSystemInformation.errors.bank_account_number}
              touched={BankSystemInformation.touched.bank_account_number}
              onChange={BankSystemInformation.handleChange(
                "bank_account_number"
              )}
              required
            />
          </Row>
          <br />
          <h2 className="title-2">PMS (Practice Management System)</h2>
          <Row>
            <DropdownInput
              label="PMS"
              required={true}
              options={pms}
              error={BankSystemInformation.errors.pms_system}
              touched={BankSystemInformation.touched.pms_system}
              onChange={BankSystemInformation.handleChange("pms_system")}
              value={BankSystemInformation.values.pms_system}
            />
          </Row>
          <br />
          <h2 className="title-2">
            Finance System
            <span style={{ display: "block" }}>
              (Please select from the list below)
            </span>
          </h2>
          <Row>
            <DropdownInput
              label="Finance System"
              required={true}
              options={financeSystem}
              error={BankSystemInformation.errors.finance_system}
              touched={BankSystemInformation.touched.finance_system}
              onChange={BankSystemInformation.handleChange("finance_system")}
              value={BankSystemInformation.values.finance_system}
            />
          </Row>
        </div>
        <p className="bottom-action text-center">
          <img
            src={arrowLeftIcon}
            onClick={() => history.push("/onboarding/practice-information")}
          />
          <Button
            label="Next"
            isDisabled={isLoading}
            customClass="button btn btn-dark-blue"
            onClick={() => {
              BankSystemInformation.handleSubmit();
            }}
          />
          <img
            src={arrowRightIcon}
            onClick={() => history.push("/onboarding/payment-completion")}
          />
        </p>
        <p style={{ padding: "20px", textAlign: "center", fontSize: "22px" }}>
          Need to subscribe more than one practice? You’ll have the option
          <br />
          to add more practices once your first subscription is finalised.
        </p>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {Information()}
      <ModalAddingPractice />
    </div>
  );
};

export default BankInformation;
