import "./Onboarding.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { sendEmailVerification } from "../../api/authorisation";
import { useFormik } from "formik";
import { SEO } from "../../components/app";
import { Button } from "../../components/common";
import {
  isNotEmpty,
  isEmail,
  isValidPassword,
  isEqualValue,
} from "../../utils/formik-validate";
import { InputOption } from "../../types/inputs";
import { Row, Modal } from "../../components/layout";
import { TextInput, DropdownInput } from "../../components/input";
import { ApplicationState } from "../../store";
import { actionCreators } from "../../store/Auth";
import { actionCreators as userActionCreators } from "../../store/User";
import { UserOnboardingData } from "../../types/user";
import history from "../../utils/history";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  arrowRightIcon,
} from "./Module";

const AccountRegistration: React.FC = () => {
  const recaptchaRef = useRef(null);
  const [recaptchaState, setRecaptchaState] = useState<string | null>(null);
  const [reload, setReload] = useState(false);
  const [showCaptcha, setshowCaptcha] = useState(false);
  const { createUserOnboarding, fetchUserData } = bindActionCreators(
    { ...actionCreators, ...userActionCreators },
    useDispatch()
  );
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      (async () => {
        await fetchUserData();
        history.push("/onboarding/practice-information");
      })();
    }
  }, [isAuthenticated]);
  const AccountSetup = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      return setshowCaptcha(true);
    },
    validate: async ({ email, password, confirmPassword }) => {
      const errors = {
        ...isNotEmpty(email, "email"),
        ...isEmail(email, "email"),
        ...isNotEmpty(password, "password"),
        ...isValidPassword(password, "password"),
        ...isNotEmpty(confirmPassword, "confirmPassword"),
        ...isValidPassword(confirmPassword, "confirmPassword"),
        ...isEqualValue(
          password,
          confirmPassword,
          "confirmPassword",
          "Password not match!"
        ),
      };
      console.log({ errors });
      return errors;
    },
  });
  const AccountSetupRender = () => {
    return (
      <div className="content">
        <OnboardingHeader step={1} stepTotal={3} disableProgress={true} />
        <div className="content-box">
          <h2 className="title-2">Create your VetXtend Account.</h2>
          <Row>
            <TextInput
              label="Email Address"
              value={AccountSetup.values.email}
              error={AccountSetup.errors.email}
              touched={AccountSetup.touched.email}
              onChange={AccountSetup.handleChange("email")}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Create Password*"
              value={AccountSetup.values.password}
              error={AccountSetup.errors.password}
              touched={AccountSetup.touched.password}
              onChange={AccountSetup.handleChange("password")}
              type="password"
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Confirm Password*"
              value={AccountSetup.values.confirmPassword}
              error={AccountSetup.errors.confirmPassword}
              touched={AccountSetup.touched.confirmPassword}
              onChange={AccountSetup.handleChange("confirmPassword")}
              type="password"
              required
            />
          </Row>
        </div>
        <p className="bottom-action text-center">
          <Button
            label="Create VetXtend Account"
            isDisabled={isLoading}
            customClass="button btn btn-dark-blue"
            onClick={async () => {
              AccountSetup.handleSubmit();
            }}
          />
        </p>
        <p style={{ textAlign: "center" }}>
          Already have a VetXtend account?
          <br />
          <a href="/">
            <strong>Login Here</strong>
          </a>
        </p>
      </div>
    );
  };
  const theCaptcha = () => {
    if (!showCaptcha) return;
    return (
      <div className="captcha">
        <div className="content-box">
          <span
            className="close"
            onClick={() => {
              setshowCaptcha(false);
            }}
          >
            x
          </span>
          <h2 className="title-2">Let us know that you aren't a bot.</h2>
          <ReCAPTCHA
            sitekey="6LezQ5YUAAAAAEKPX18beuQ-z6m-mNA8AZCpa4cS"
            onChange={async (value) => {
              if (!value) return;
              setIsLoading(true);
              console.log({ value, values: AccountSetup.values });
              const { email, password } = AccountSetup.values;
              //const data: any = { email, password };
              await createUserOnboarding(email, password);
              setshowCaptcha(false);
              setIsLoading(false);
            }}
          />
        </div>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className={isLoading ? `onboarding isloading` : `onboarding`}>
      <SEO title="Account Setup" />
      <div className="banner"></div>
      {AccountSetupRender()}
      {theCaptcha()}
    </div>
  );
};

export default AccountRegistration;
