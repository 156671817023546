import { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as userActionCreators } from "../../store/User";
import { actionCreators as commonActionCreators } from "../../store/Common";
import { ApplicationState } from "../../store";
import { Modal } from "../../components/layout";
import history from "../../utils/history";
import { PracticeDetails } from "./DashboardModule";
import {
  ReportOnlineSales,
  ReportCommission,
  ReportWellnessPlan,
  ReportWellnessPlanSubscription,
  RebateStatements,
} from "./ReportModule";

const imageBaseUrl: any = `${process.env.REACT_APP_BACKEND_API}${process.env?.REACT_APP_BACKEND_IMAGE_ENDPOINT}`;
const Dashboard: React.FC = () => {
  const NavRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const { view } = useParams<any>();
  const [show, setShow] = useState<{
    planInclusion: boolean;
    iconTab: string;
  }>({ planInclusion: false, iconTab: "practice-details" });

  useEffect(() => {
    if (!isAuthenticated) return history.push("/");
    if (view) {
      setShow({ ...show, iconTab: view });
      NavRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
    console.log({ user, view });
    changeActionType(undefined);
  }, [view, user?.practice_id, isAuthenticated]);
  /*useEffect(() => {
    if (!user) return;
    //user.subscription_plan = undefined;
    console.log({ user });
  }, []);*/
  /*
   * RENDERING
   */
  if (!user?.subscription_plan || user?.subscription_status != "active")
    return (
      <div className={`dashboard`}>
        <div className="section-row">
          <div
            className="container"
            style={{
              borderBottom: "1px solid #a2d0e2",
              paddingBottom: "40px",
            }}
          >
            <a
              onClick={() => {
                history.push("/dashboard/my-practices");
              }}
            >
              <img
                className="image-preview logo-upload"
                src={imageBaseUrl + user?.image_url}
              />
            </a>
            <div>
              <h2 className="title-2">
                Hi {user?.practice_name}, welcome to your VetXtend Portal.
              </h2>
              <p>From this portal, you can manage your VetXtend account</p>
            </div>
          </div>
        </div>
        <div className="section-row">
          <div
            className="container"
            style={{
              margin: "-40px auto -20px auto",
              borderBottom: "1px solid #a2d0e2",
              paddingBottom: "20px",
            }}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              <h2 className="title-2">
                No Subscription plan added to this Practice.
              </h2>
              <br />
              <button
                className="btn btn-dark-blue"
                onClick={() => {
                  return history.push("/onboarding/payment-completion");
                }}
              >
                Subscribe Now!
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className={`dashboard`}>
      <div className="section-row">
        <div
          className="container"
          style={{
            borderBottom: "1px solid #a2d0e2",
            paddingBottom: "40px",
          }}
        >
          <a
            onClick={() => {
              history.push("/dashboard/my-practices");
            }}
          >
            <img
              className="image-preview logo-upload"
              src={imageBaseUrl + user?.image_url}
            />
          </a>
          <div>
            <h2 className="title-2">
              Hi {user?.practice_name}, welcome to your VetXtend Portal.
            </h2>
            <p>From this portal, you can manage your VetXtend account</p>
            <button
              className="btn btn-dark-blue hide"
              style={{ margin: "0", width: "auto" }}
              onClick={() => changeActionType("send-pharmacy-script")}
            >
              Send a pharmacy script
            </button>
          </div>
        </div>
      </div>
      <div className="section-row">
        <div
          className="container"
          style={{
            margin: "-40px auto -20px auto",
            borderBottom: "1px solid #a2d0e2",
            paddingBottom: "20px",
          }}
        >
          <div style={{ width: "100%" }}>
            <p
              style={{
                float: "right",
                marginTop: "0",
                cursor: "pointer",
                width: "240px",
                maxWidth: "100%",
                textAlign: "center",
              }}
              onClick={() => {
                setShow({ ...show, planInclusion: !show.planInclusion });
              }}
            >
              See Plan Inclusions ➔
              <a
                className="btn btn-dark-blue"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  //return history.push();
                  window.open("/onboarding/service-agreement", "_blank");
                }}
              >
                Download Agreement
              </a>
            </p>
            <p style={{ marginTop: "0" }}>
              Your current VetXtend plan is {""}
              <strong>
                {user?.subscription_plan == "vetxpress" && "VetXpress"}
                {user?.subscription_plan == "vetxplore" && "VetXplore"}
                {user?.subscription_plan == "vetxpand" && "VetXpand"}
              </strong>
            </p>
          </div>
        </div>
        <div
          className={show?.planInclusion ? `container` : `container none`}
          style={{ marginBottom: "-25px" }}
        >
          {[
            {
              name: "vetxpress",
              price: "299",
              frequency: "annually",
              inclusion: ["partnership-program-rebates"],
            },
            {
              name: "vetxplore",
              price: "250",
              frequency: "per month",
              inclusion: ["practice-performance-reporting"],
            },
            {
              name: "vetxpand",
              price: "500",
              frequency: "yearly",
              inclusion: [
                "partnership-program-rebates",
                "practice-performance-reporting",
                "digital-marketing-service",
              ],
            },
          ].map((m: any) => {
            return (
              <div
                className={
                  user?.subscription_plan == m.name
                    ? "wrap order-reference"
                    : "wrap order-reference hide"
                }
              >
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <td>Partnership Program Rebates</td>
                        <td>Practice Performance Reporting</td>
                        <td>
                          Digital Marketing Service <br />
                          <span
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                            }}
                          >
                            (Education & Retail Communications)
                          </span>
                        </td>
                        <td>TOTAL PRICE</td>
                      </tr>
                    </thead>
                    {user?.subscription_plan && (
                      <tbody>
                        <tr>
                          <td>
                            {m.inclusion?.includes(
                              "partnership-program-rebates"
                            ) ? (
                              <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                            ) : (
                              <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                            )}
                          </td>
                          <td>
                            {m.inclusion?.includes(
                              "practice-performance-reporting"
                            ) ? (
                              <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                            ) : (
                              <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                            )}
                          </td>
                          <td>
                            {m.inclusion?.includes(
                              "digital-marketing-service"
                            ) ? (
                              <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                            ) : (
                              <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                            )}
                          </td>
                          <td>
                            <strong>${m.price}</strong>
                            <br />
                            {m.frequency}
                          </td>
                        </tr>
                        {user?.subscription_addon == "core" && (
                          <tr>
                            <td
                              colSpan={3}
                              style={{
                                background: "#fff",
                                border: "1px solid #ededed",
                                padding: "0",
                              }}
                            >
                              <p
                                style={{
                                  background: "#64aadc",
                                  margin: "0",
                                  color: "#fff",
                                  lineHeight: "60px",
                                  fontWeight: "bold",
                                }}
                              >
                                VetXpand Marketing Bundle Add-on
                              </p>
                              <p
                                style={{
                                  textAlign: "justify",
                                  padding: "20px",
                                }}
                              >
                                <strong>Core Marketing emails include: </strong>
                                NPS Customer Satisfaction Surveys, New Client
                                Welcome, We Miss You (Lapsed Patients), Pet
                                Birthday, Lifestage Communications (Puppy,
                                Kitten, Adolescent, Adult, Senior and Geriatric)
                                + up to 4 others of your choice sent to your
                                entire database.
                              </p>
                            </td>
                            <td style={{ background: "#f6f9fa" }}>
                              <strong>$199</strong>
                              <br />
                              monthly
                            </td>
                          </tr>
                        )}
                        {user?.subscription_addon == "reminder" && (
                          <tr>
                            <td
                              colSpan={3}
                              style={{
                                background: "#fff",
                                border: "1px solid #ededed",
                                padding: "0",
                              }}
                            >
                              <p
                                style={{
                                  background: "#64aadc",
                                  margin: "0",
                                  color: "#fff",
                                  lineHeight: "60px",
                                  fontWeight: "bold",
                                }}
                              >
                                VetXpand Marketing Bundle Add-on
                              </p>
                              <p
                                style={{
                                  textAlign: "justify",
                                  padding: "20px",
                                }}
                              >
                                <strong>Reminder Bundle Add-On: </strong>
                                Cat and dog desexing, vaccination, 6-monthly
                                senior, annual and overdue reminders.
                              </p>
                            </td>
                            <td style={{ background: "#f6f9fa" }}>
                              <strong>$299</strong>
                              <br />
                              monthly
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            );
          })}
        </div>
        {user?.subscription_status == "active" && (
          <div className="container">
            <div style={{ width: "100%", marginTop: "40px" }}>
              <a
                className="btn btn-dark-blue"
                style={{
                  float: "right",
                  lineHeight: "30px",
                  width: "240px",
                  maxWidth: "100%",
                }}
                onClick={() => {
                  return history.push("/dashboard/upgrade-plan");
                }}
              >
                Upgrade Plan
              </a>
              <p style={{ marginTop: "0" }}>
                <strong>Need to upgrade your current plan?</strong>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="section-row icon-tabs">
        <div className="container row">
          <div
            className={
              show.iconTab == "practice-details"
                ? "inner-box active"
                : "inner-box"
            }
            onClick={() => {
              return history.push("/dashboard/practice-details");
            }}
          >
            <div className="icon-box">
              {show.iconTab == "practice-details" ? (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/practice-details-active.png" />
              ) : (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/practice-details.png" />
              )}
            </div>
            Practice <br />
            Details
          </div>
          <div
            className={
              show.iconTab == "sales-snapshot"
                ? "inner-box active"
                : "inner-box"
            }
            onClick={() => {
              return history.push("/dashboard/sales-snapshot");
            }}
          >
            <div className="icon-box">
              {show.iconTab == "sales-snapshot" ? (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/online-sales-active.png" />
              ) : (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/online-sales.png" />
              )}
            </div>
            Online <br />
            Sales
          </div>
          <div
            className={
              show.iconTab == "commission-summary"
                ? "inner-box active"
                : "inner-box"
            }
            onClick={() => {
              return history.push("/dashboard/commission-summary");
            }}
          >
            <div className="icon-box">
              {show.iconTab == "commission-summary" ? (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/commission-summary-active.png" />
              ) : (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/commission-summary.png" />
              )}
            </div>
            Commission <br />
            Summary
          </div>
          <div
            className={
              show.iconTab == "wellness-plan" ? "inner-box active" : "inner-box"
            }
            onClick={() => {
              return history.push("/dashboard/wellness-plan");
            }}
          >
            <div className="icon-box">
              {show.iconTab == "wellness-plan" ? (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/wellness-plan-active.png" />
              ) : (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/wellness-plan.png" />
              )}
            </div>
            Wellness Plan
            <br />
            Summary
          </div>
          <div
            className={
              show.iconTab == "rebate-statements"
                ? "inner-box active"
                : "inner-box"
            }
            onClick={() => {
              return history.push("/dashboard/rebate-statements");
            }}
          >
            <div className="icon-box">
              {show.iconTab == "rebate-statements" ? (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/rebate-statement-active.png" />
              ) : (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/11/rebate-statement.png" />
              )}
            </div>
            Rebates <br />
            Statement
          </div>
          <div
            className={
              show.iconTab == "reporting-dashboard"
                ? "inner-box active"
                : "inner-box"
            }
            onClick={() => {
              return history.push("/dashboard/reporting-dashboard");
            }}
          >
            <div className="icon-box">
              {show.iconTab == "reporting-dashboard" ? (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/12/reporting-dashboard-active.png" />
              ) : (
                <img src="https://vetxtend.com.au/wp-content/uploads/2024/12/reporting-dashboard.png" />
              )}
            </div>
            Reporting <br />
            Dashboard
          </div>
        </div>
      </div>
      <div ref={NavRef} className="section-row">
        {show.iconTab == "practice-details" && <PracticeDetails />}
        {show.iconTab == "wellness-plan" && <ReportWellnessPlan />}
        {show.iconTab == "wellness-plan-subscription" && (
          <ReportWellnessPlanSubscription />
        )}
        {show.iconTab == "rebate-statements" && <RebateStatements />}
        {show.iconTab == "commission-summary" && <ReportCommission />}
        {show.iconTab == "sales-snapshot" && <ReportOnlineSales />}
        {show.iconTab == "reporting-dashboard" && (
          <div className="section-row">
            <div
              className="container"
              style={{
                display: "block",
                textAlign: "center",
                width: "800px",
                maxWidth: "100%",
              }}
            >
              <a
                href="https://app.powerbi.com/groups/me/apps/31f6ae7f-ea36-454c-a983-a9c7fe4612fa?ctid=33995b3a-000b-4fbf-9479-8518ac19be26"
                className="btn btn-dark-blue"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                Take me to my Reporting Dashboard
              </a>
              <br />
              <p>
                Don’t have a Reporting Dashboard with us? You can learn more
                about what insights we can provide you and your practice, as
                well as schedule in a time for a {""}
                <a
                  href="https://vetxtend.com.au/partnership-level/#practice-performance"
                  target="_blank"
                >
                  demo
                </a>
                {""} here.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Dashboard;
