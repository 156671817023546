import axios from "../api/axios";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  User,
  UserUpdateData,
  PracticeInformationType,
  PracticeBankAndSystemType,
} from "../types/user";
import { LogoutAction } from "./Auth";
import {
  DeleteReason,
  Pet,
  PetSignupData,
  PetOnboarding,
  PetUpdateData,
} from "../types/pet";
import { toast } from "react-toastify";
import { getClaims } from "../api/claim";
import { AddClaimRequest, AddClaimMultipleRequest } from "../types/claims";
import { uploadImage, uploadInvoice } from "../api/upload";
const API = process.env.REACT_APP_BACKEND_API;

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface UserState extends User {
  _id?: string;
  email: string;
  bank?: {
    accountName?: string;
    acc?: string;
    bsb?: string;
  };
  bank_account_name?: string;
  bank_account_bsb?: string;
  bank_account_number?: string;
  pms_system?: string;
  finance_system?: string;
  card?: {
    masked_number?: string;
    expiry_month?: string;
    expiry_year?: string;
  };
  affiliateName?: string;
  affiliateCode?: string;
  practice_id?: string;
  practice_key?: string;
  practice_name?: string;
  coaching_group?: string;
  contact_person?: string;
  contact_firstname?: string;
  contact_lastname?: string;
  contact_email?: string;
  contact_jobtitle?: string;
  website?: string;
  image_url?: string;
  role?: string;
  status?: string;
  subscription_id?: string;
  subscription_plan?: string;
  subscription_addon_id?: string;
  subscription_addon?: string;
  subscription_status?: string;
  showReferralCode?: boolean;
  showPasswordChanges?: boolean;
  practices?: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface FetchUserDataAction {
  type: "RECEIVE_USER_DATA";
  payload: User;
}

export interface FetchChargeebeeDataAction {
  type: "RECEIVE_CHARGEBEE_DATA";
  payload: {
    card: {
      masked_number?: string;
      expiry_month?: string;
      expiry_year?: string;
    };
  };
}

export interface FetchBankDataAction {
  type: "RECEIVE_BANK_DATA";
  payload: {
    bank: {
      accountName?: string;
      acc?: string;
      bsb?: string;
    };
  };
}

export interface FetchUserPractices {
  type: "RECEIVE_USER_PRACTICES";
  payload: {
    practices?: any;
  };
}

export interface UpdatePracticeInformation {
  type: "DISPATCH_PRACTICE_INFORMATION";
  payload: {
    practice_name?: string;
    website?: string;
    phone: string;
    subscription_id?: string;
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      country: string;
      province: string;
      postcode: string;
    };
  };
}

export interface UpdatePracticeBankAndSystem {
  type: "DISPATCH_PRACTICE_BANKANDSYSTEM";
  payload: {
    practice_name?: string;
    pms_system?: string;
    finance_system?: string;
    bank_account_name?: string;
    bank_account_bsb?: string;
    bank_account_number?: string;
  };
}

export interface UpdatePetDataAction {
  type: "UPDATE_PET_DATA";
}

export interface UpdateViewChanges {
  type: "UPDATE_VIEW_CHANGES";
  payload: {
    showReferralCode?: boolean;
    showPasswordChanges?: boolean;
  };
}

export interface AddPetDataAction {
  type: "ADD_PET";
}

export interface AddClaimAction {
  type: "ADD_CLAIM";
}

export interface OnboardPetAction {
  type: "ONBOARD_PET";
  payload: User;
}

export interface DeletePetDataAction {
  type: "DELETE_PET_DATA";
  petId: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | LogoutAction
  | FetchUserDataAction
  | FetchChargeebeeDataAction
  | FetchBankDataAction
  | FetchUserPractices
  | AddPetDataAction
  | OnboardPetAction
  | UpdatePetDataAction
  | DeletePetDataAction
  | AddClaimAction
  | UpdatePracticeInformation
  | UpdatePracticeBankAndSystem
  | UpdateViewChanges;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  fetchUserData:
    (pid?: string | number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      pid = pid ? pid : appState?.user?.practice_id;
      console.log({ practice_id: pid, user: appState?.user });
      if (appState && appState.auth?.email) {
        try {
          const res = await axios.post<User>(`${API}/profile/detail`, {
            email: appState.auth.email,
            practice_id: pid,
          });
          if (res.status === 200) {
            dispatch({ type: "RECEIVE_USER_DATA", payload: res.data });
          }
        } catch (error) {
          toast.error("Failed to fetch user data");
        }
      }
    },
  fetchChargeBeeData:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          const res = await axios.post(`${API}/chargebee/get-customer-detail`, {
            customer_id: appState?.user?._id,
            country: appState?.user?.address?.country,
          });
          if (res.status === 200) {
            const { card } = res.data;
            dispatch({ type: "RECEIVE_CHARGEBEE_DATA", payload: { card } });
          }
        } catch {
          toast.error("Failed get chargebee data.");
        }
      }
    },
  fetchBankData:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          const { user } = appState;
          if (!user || !user?.pets) return;
          const claimIds = await user?.pets
            .map((p) => {
              return p.claims;
            })
            .filter((c) => {
              return c.length > 0;
            })
            .reduce((a: any, c: any) => {
              return a.concat(c);
            }, []);
          const theClaim: any = (await getClaims(claimIds)).reverse()[0];
          if (user.bank || !theClaim) return;
          const { accountNumber: acc, bsb } = theClaim.reimbursement;
          const accountName = `${user.firstName} ${user.lastName}`;
          dispatch({
            type: "RECEIVE_BANK_DATA",
            payload: {
              bank: { accountName, acc, bsb },
            },
          });
          console.log({ user, theClaim });
        } catch {
          toast.error("Failed get chargebee data.");
        }
      }
    },
  fetchPractices:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      //console.log({ appState });
      if (appState && appState.auth?.email) {
        try {
          const email = appState.auth.email;
          const res = await axios.post<UserState>(
            `${API}/profile/get-practices`,
            { email }
          );
          if (res.status === 200) {
            dispatch({ type: "RECEIVE_USER_PRACTICES", payload: res.data });
          }
        } catch (error) {
          toast.error("Failed to fetch user data");
        }
      }
    },
  updateUserData:
    (userData: UserUpdateData): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          const res = await axios.post(`${API}/update/user`, {
            ...userData,
          });
          if (res.status === 200) {
            dispatch({ type: "ADD_PET" });
          } else {
            throw new Error();
          }
        } catch {
          toast.error("Failed to update account details.");
        }
      }
    },
  updatePractice:
    (data: any): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          if (data.image) {
            const photo = await uploadImage(data.image);
            if (photo && photo.status === 200) {
              data.image_url = photo.data.blobName;
            }
          }
          console.log({ data });
          const res = await axios.post(`${API}/profile/update-practice`, {
            ...data,
          });
          console.log({ res, data });
          if (res.status === 200) {
            dispatch({ type: "RECEIVE_USER_PRACTICES", payload: res.data });
            toast.success("Successfully updated practice information");
          } else {
            throw new Error();
          }
        } catch {
          toast.error("Failed to update account details.");
        }
      }
    },
  updatePracticeInformation:
    (data: PracticeInformationType): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      data.practice_id = appState.user?.practice_id;
      if (appState && appState.auth?.email) {
        try {
          if (data.image) {
            const photo = await uploadImage(data.image);
            if (photo && photo.status === 200) {
              data.image_url = photo.data.blobName;
            }
          }
          //return console.log({ data });
          const res = await axios.post(
            `${API}/profile/update-practice-information`,
            { ...data }
          );
          console.log({ res, data });
          if (res.status === 200) {
            dispatch({
              type: "DISPATCH_PRACTICE_INFORMATION",
              payload: res.data,
            });
          } else {
            throw new Error();
          }
        } catch {
          toast.error("Failed to update account details.");
        }
      }
    },
  updatePracticeBankAndSystem:
    (data: PracticeBankAndSystemType): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      //return console.log("DISPATCH", { data });
      if (appState && appState.auth?.email) {
        try {
          const res = await axios.post(
            `${API}/profile/update-practice-bankandsystem`,
            { ...data, practice_id: appState.user?.practice_id }
          );
          if (res.status === 200) {
            dispatch({
              type: "DISPATCH_PRACTICE_BANKANDSYSTEM",
              payload: res.data,
            });
          } else {
            throw new Error();
          }
        } catch {
          toast.error("Failed to update account details.");
        }
      }
    },
  updateUserBankInfo:
    (bank: {
      accountName: string;
      acc: string;
      bsb: string;
    }): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          const res = await axios.post(`${API}/update/user`, {
            bank,
            email: appState.auth?.email,
          });
          console.log({ res });
          /*if (res.status === 200) {
            dispatch({ type: "ADD_PET" });
            toast.success(`Account details successfully updated.`);
          } else {
            throw new Error();
          }*/
        } catch {
          toast.error("Failed to update account details.");
        }
      }
    },
  updateViewChanges:
    (view: {
      showReferralCode?: boolean;
      showPasswordChanges?: boolean;
    }): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          dispatch({ type: "UPDATE_VIEW_CHANGES", payload: view });
        } catch {
          toast.error("Failed to update changes");
        }
      }
    },
  addNewPractice:
    (data: PracticeInformationType): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          if (data.image) {
            const photo = await uploadImage(data.image);
            if (photo && photo.status === 200) {
              data.image_url = photo.data.blobName;
            }
          }
          const res = await axios.post(`${API}/profile/add-new-practice`, {
            ...data,
          });
          if (res.status === 200) {
            dispatch({
              type: "DISPATCH_PRACTICE_INFORMATION",
              payload: res.data,
            });
          } else {
            throw new Error();
          }
        } catch {
          toast.error("Failed to update account details.");
        }
      }
    },
  addPet:
    (petData: PetSignupData): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          if (petData.image) {
            const photo = await uploadImage(petData.image);
            if (photo && photo.status === 200) {
              petData.imageUrl = photo.data.blobName;
              const res = await axios.post(`${API}/update/newpet`, {
                user: appState.auth.email,
                pet: petData,
              });
              if (res.status === 200) {
                dispatch({ type: "ADD_PET" });
                toast.success(`${petData.name} successfully added.`);
              } else {
                throw new Error();
              }
            } else {
              toast.error("Failed to upload image. Please try again later.");
              throw new Error("Failed to upload image.");
            }
          } else {
            // no image, just create pet
            const res = await axios.post(`${API}/update/newpet`, {
              user: appState.auth.email,
              pet: petData,
            });
            if (res.status === 200) {
              dispatch({ type: "ADD_PET" });
              toast.success(`${petData.name} successfully added.`);
            } else {
              throw new Error();
            }
          }
        } catch {
          toast.error("Failed to add pet.");
        }
      }
    },
  onBoardPet:
    (pets: PetOnboarding[]): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        pets.every(async (pet: PetOnboarding) => {
          //console.log({ p });
          try {
            if (pet.image) {
              const photo = await uploadImage(pet.image);
              if (photo && photo.status === 200) {
                pet.imageUrl = photo.data.blobName;
              }
            }
            const res = await axios.post<User>(`${API}/update/newpet`, {
              user: appState?.auth?.email,
              pet,
            });
            if (res.status === 200) {
              dispatch({ type: "ONBOARD_PET", payload: res.data });
            } else {
              throw new Error();
            }
          } catch (e) {
            toast.error("Failed to add pet.");
          }
        });
      }
    },
  addClaim:
    ({
      claim,
      pet,
      user,
      reimbursement,
    }: AddClaimRequest): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          const invoice = await uploadInvoice(claim.invoice);
          if (invoice && invoice.status === 200) {
            claim.invoice = invoice.data.blobName;
          } else {
            toast.error("Failed to create claim.");
            throw new Error("Failed to upload invoice");
          }
          const res = await axios.post(`${API}/portal/claims/create`, {
            type: claim.type,
            invoice: claim.invoice,
            customer: user,
            pet: pet,
            reimbursement: reimbursement,
            date: new Date(),
            vet: claim.vet,
          });
          toast.success("Claim has been successfully created.");
          return res;
        } catch {
          toast.error("Failed to create claim.");
        }
      }
    },
  addMultipleClaim:
    ({
      claim,
      pet,
      user,
      reimbursement,
    }: AddClaimMultipleRequest): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          if (!Array.isArray(claim.type) || claim.type.length < 1)
            return toast.error("No claim type indicated.");
          const invoice = await uploadInvoice(claim.invoice);
          if (invoice && invoice.status === 200) {
            claim.invoice = invoice.data.blobName;
          } else {
            toast.error("Failed to create claim.");
            throw new Error("Failed to upload invoice");
          }
          claim.type.map(async (type: any) => {
            reimbursement.amount = type.amount;
            const arg = {
              type: type.value,
              invoice: claim.invoice,
              customer: user,
              pet: pet,
              reimbursement,
              date: new Date(),
              vet: claim.vet,
            };
            const res = await axios.post(`${API}/portal/claims/create`, arg);
            if (res.status != 200)
              return toast.error(
                `${pet.name} ${type.label} Failed to create claim.`
              );
            return toast.success(
              `${pet.name} ${type.label} has been successfully created.`
            );
          });
        } catch {
          toast.error("Failed to create claim.");
        }
      }
    },
  updatePetData:
    (petData: PetUpdateData): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          if (petData.image) {
            const photo = await uploadImage(petData.image);
            if (photo && photo.status === 200) {
              petData.imageUrl = photo.data.blobName;
              const res = await axios.post(`${API}/update/pet`, {
                user: appState.auth.email,
                pet: petData,
              });
              if (res.status === 200) {
                dispatch({ type: "UPDATE_PET_DATA" });
                toast.success("Pet updated successfully.");
              } else {
                throw new Error();
              }
            }
          } else {
            const res = await axios.post(`${API}/update/pet`, {
              user: appState.auth.email,
              pet: petData,
            });
            if (res.status === 200) {
              dispatch({ type: "UPDATE_PET_DATA" });
              toast.success("Pet updated successfully.");
            } else {
              throw new Error();
            }
          }
        } catch {
          toast.error("Failed to update pet data.");
        }
      }
    },
  deletePetData:
    (petId: Pet["_id"], reason: DeleteReason): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.auth?.email) {
        try {
          const res = await axios.delete(
            `${API}/update/pet?petId=${petId}&user=${appState.auth.email}&reason=${reason}`
          );
          if (res.status === 200) {
            dispatch({ type: "DELETE_PET_DATA", petId });
          }
        } catch {
          toast.error("Failed to delete pet.");
        }
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<UserState | undefined> = (
  state,
  incomingAction: Action
): UserState => {
  const defaultState: UserState = {
    _id: undefined,
    //firstName: "",
    //lastName: "",
    imageUrl: "",
    email: "",
    phone: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      province: "",
      postcode: "",
    },
    bank: undefined,
    password: "",
    interests: [],
    pets: [],
    accountStatus: "active",
    resourceCreds: null,
    card: undefined,
    showReferralCode: true,
    showPasswordChanges: false,
    practices: [],
  };

  if (state === undefined) {
    return defaultState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "LOGOUT":
      return defaultState;
    case "DELETE_PET_DATA":
      const Pets = state.pets.filter((v) => {
        return v._id != action.petId;
      });
      state = { ...state, pets: Pets };
      return state;
    case "ONBOARD_PET":
      const { pets } = action.payload;
      state = { ...state, pets };
      return state;
    case "RECEIVE_USER_DATA":
      //console.log("DISPATCH USER DATA", action.payload);
      return { ...state, ...action.payload };
    case "RECEIVE_CHARGEBEE_DATA":
      return { ...state, ...action.payload };
    case "RECEIVE_BANK_DATA":
      return { ...state, ...action.payload };
    case "RECEIVE_USER_PRACTICES":
      console.log({ practices: action.payload });
      return { ...state, practices: action.payload };
    case "DISPATCH_PRACTICE_INFORMATION":
      return { ...state, ...action.payload };
    case "DISPATCH_PRACTICE_BANKANDSYSTEM":
      return { ...state, ...action.payload };
    case "UPDATE_VIEW_CHANGES":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
