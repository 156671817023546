import "./Footer.scss";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { bindActionCreators } from "redux";
import history from "../../../utils/history";
import { actionCreators as commonActionCreators } from "../../../store/Common";
import { Container } from "../../layout";
import logo from "../../../assets/logo.svg";
import logoFooterIcon from "../../../assets/images/thrivingpets-logo-footer.svg";
import phoneIcon from "../../../assets/images/phone.png";
import emailIcon from "../../../assets/icons/email.svg";
import homeIcon from "../../../assets/icons/home-light.svg";
import vetXtendLogoLight from "../../../assets/images/vetxtend-logo-light.svg";
import { menuLinks } from "./FooterLinks";
import { useEffect, useState } from "react";

const YPPA_DOMAIN =
  window.location.host != "account.thrivingpets.co.nz"
    ? "yourpetpa.com.au"
    : "yourpetpa.co.nz";
export interface MenuItem {
  /**
   * The label to be displayed in the menu link.
   */
  label: string;
  /**
   * The function to be called when the menu link is clicked.
   */
  href: string;
  external?: boolean;
}

export interface SocialItems {
  /**
   * The icon to be displayed.
   */
  icon: string;
  /**
   * The function to be called when the icon is clicked.
   */
  href: string;
}

/**
 * The footer of the website.
 */
const Footer: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const [userActive, setUserActive] = useState(false);
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const renderMenuItems = (menu: MenuItem[]) => {
    const listItems = menu.map(({ label, href, external }) => (
      <li>
        {!external ? (
          <a onClick={() => history.push(href)}>{label}</a>
        ) : (
          <a href={href}>{label}</a>
        )}
      </li>
    ));
    return <ul>{listItems}</ul>;
  };

  useEffect(() => {
    setUserActive(
      user?.subscription_plan && user?.subscription_status == "active"
        ? true
        : false
    );
  }, [user]);

  if (!isAuthenticated) return null;

  return (
    <footer className="footer">
      <div className="container">
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: "40px",
            fontSize: "30px",
          }}
        >
          Interested to learn more about how VetXtend
          <br />
          can support your practice?
          <br />
          <br />
          <a
            href="https://calendly.com/psadler-yourpetpa/learn-more-about-partnership-program-and-rebates"
            target="_blank"
            className="btn btn-blue"
            style={{
              display: "inline-block",
              borderRadius: "40px",
              padding: "20px",
            }}
          >
            Book a chat with us
          </a>
        </p>
        <div style={{ padding: "0px 20px 35px", borderBottom: "1px solid" }}>
          {userActive && (
            <a
              className="btn btn-dark-blue hide"
              style={{ float: "right" }}
              onClick={() => changeActionType("send-pharmacy-script")}
            >
              Send a pharmacy script
            </a>
          )}
        </div>
        <div className="footer__row">
          <div className="footer__col">
            <h4>I want to update my:</h4>
            {renderMenuItems(menuLinks.menu1)}
          </div>
          <div className="footer__col">
            <h4>I want to view my:</h4>
            {renderMenuItems(menuLinks.menu2)}
          </div>
          <div className="footer__col">
            <h4>I want to access:</h4>
            {renderMenuItems(menuLinks.menu3)}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
