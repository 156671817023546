import axios from "../api/axios";
import { uploadPrescriptionVetXtend } from "./upload";
interface AffiliateOption {
  id: string;
  name: string;
}

export const ValidateAccountLink = async (uuid: string): Promise<any> => {
  return axios.post<any>("/account/validate-acount", { uuid });
};

export const RequestPasswordReset = async (email: string): Promise<any> => {
  const host = window.location.href.replace(window.location.pathname, "");
  return axios.post<any>("/account/request-password-reset", { email, host });
};

export const ValidateResetlink = async (code: string): Promise<any> => {
  return axios.post<any>("/account/validate-reset", { code });
};

/*export const ValidateSubscription = async (
  practice_id?: string
): Promise<any> => {
  //ret: subscription_status
  return axios.post<any>("/profile/validate-subscription", { practice_id });
};*/

export const GetSubscription = async (
  subscription_id?: string
): Promise<any> => {
  return axios.post<any>("/chargebee/retrieve-subscription", {
    subscription_id,
  });
};

export const SendQRcodeViaEmail = async (
  image_url?: string,
  link?: string
): Promise<any> => {
  //ret: subscription_status
  const host = window.location.href.replace(window.location.pathname, "");
  return axios.post<any>("/profile/email-qrcode", { image_url, link, host });
};

export const SubmitNewPassword = async (
  code: string,
  password: string
): Promise<any> => {
  const host = window.location.href.replace(window.location.pathname, "");
  return axios.post<any>("/account/submit-new-password", {
    code,
    password,
    host,
  });
};

export const SubmitContactUs = async (data: any): Promise<any> => {
  return axios.post<any>("/account/contact-us", data);
};

export const SubmitPharmacyScript = async (
  data: any,
  attachment: any
): Promise<any> => {
  //data.attachment_link = `http://localhost:4000/v1.2/assets/prescriptions?name=17321676180275578.pdf`;
  //return console.log({ data });
  //return axios.post<any>("/account/send-pharmacy-script", data);
  return uploadPrescriptionVetXtend(attachment).then(
    (r: any): Promise<any> | void => {
      console.log({ r });
      if (!r || !r.data) return console.log(`uploading error`);
      const { blobName } = r.data;
      data.attachment_link = `${process.env.REACT_APP_BACKEND_API}/assets/prescriptions?name=${blobName}`;
      return axios.post<any>("/account/send-pharmacy-script", data);
    }
  );
};
