import "./TextArea.scss";
import { FocusProps, InputProps } from "../../../types/inputs";
import { InputError, InputLabel } from "..";

interface TextInputProps extends InputProps, FocusProps<HTMLInputElement> {
  /**
   * The type of text input. The default is 'text'.
   */

  notes?: string;

  notesBottom?: string;

  min?: number;

  max?: number;

  placeholder?: string;

  rows?: number;
}

const TextArea: React.FC<TextInputProps> = ({
  label,
  notes,
  notesBottom,
  value,
  onChange,
  touched,
  error,
  required,
  disabled = false,
  rows = 3,
}) => {
  return (
    <div className="textinput">
      <InputLabel label={label} notes={notes} required={required} />
      <textarea
        className="textinput__input"
        disabled={disabled}
        rows={rows}
        onChange={(e) => onChange(e.target.value || "")}
      >
        {value}
      </textarea>
      <InputError error={error} touched={touched} />
      {notesBottom && <span>{notesBottom}</span>}
    </div>
  );
};

export default TextArea;
