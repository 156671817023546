import "./Sidebar.scss";
import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import logoBox from "../../../assets/img/logo-box.svg";
import arrowRightIcon from "../../../assets/icons/arrow-right.svg";
import history from "../../../utils/history";

interface MenuItem {
  /**
   * The label to be displayed in the menu link.
   */
  label: string;
  /**
   * The icon to be displayed next to the label.
   */
  iconUrl?: string;
  /**
   * The function to be called when the menu link is clicked.
   */
  onClick: () => void;
}

interface SidebarProps {
  /**
   * Menu links to be rendered in the sidebar.
   */
  menuItems: MenuItem[];
  /**
   * Whether the sidebar is open or closed.
   */
  isOpen: boolean;
  /**
   * Function to be called to close the sidebar
   * from inside the component.
   */
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ menuItems, isOpen, onClose }) => {
  const modifier = isOpen ? "open" : "close";

  const user = useSelector((state: ApplicationState) => state.user);

  return (
    <div className={`sidebar sidebar-${modifier}`}>
      {isOpen && <div className="sidebar__overlay" onClick={onClose} />}
      <div className={`sidebar__ui sidebar__ui-${modifier}`}>
        <div className="sidebar__header">
          <img className="sidebar__logo" src={logoBox} />
          <span className="profileName">
            <span
              className="title"
              style={{
                textTransform: "capitalize",
                maxWidth: "250px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "block",
                whiteSpace: "nowrap",
              }}
            >
              {user?.practice_name ? `${user?.practice_name}` : `VetXtend`}
            </span>
            <a
              className="name"
              onClick={() => {
                onClose();
                history.push("/");
              }}
            >
              {user?.email
                ? `${user?.email}`
                : `An extension of your veterinary practice`}
            </a>
          </span>
          <div className="sidebar__exit" onClick={onClose}>
            <img src={arrowRightIcon} />
          </div>
        </div>
        <nav className="sidebar__nav">
          <ul className="sidebar__ul">
            {menuItems.map(({ label, iconUrl, onClick }) => (
              <li className="sidebar__li">
                <a
                  className="sidebar__link"
                  onClick={() => {
                    onClick();
                    onClose();
                  }}
                >
                  <img className="sidebar__linkicon" src={iconUrl} />
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
