import React, { useEffect, useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Row } from "../../layout";
import { TextInput, DropdownInput } from "../../input";
import { InputOption } from "../../../types/inputs";
import { componentsToAddress } from "../../../utils/google-address";
import { Address } from "../../../types/address";

interface FormikFormWithAddress {
  values: Address;
  errors: {
    [key in keyof Address]?: string;
  };
  touched: {
    [key in keyof Address]?: boolean;
  };
  handleChange: any;
}

interface AddressInputProps {
  /**
   * An instance of the parent form.
   */
  form: FormikFormWithAddress;
}

const AddressInput: React.VFC<AddressInputProps> = ({ form }) => {
  /**
   * Google configuration.
   * AIzaSyAV0x0Ax7L-Hh7tHrDLvYIOGtKOpVf6PmM
   */
  const { placePredictions, getPlacePredictions, placesService } = useGoogle({
    apiKey: "AIzaSyBR10G0vqTr0f0Fa1guSQdaeaSMlFagmW8",
    debounce: 100,
  });

  /**
   * Google search query and state logic for searching on text input.
   */
  const [addressQuery, setAddressQuery] = React.useState("");
  const [predictions, setPredictions] = useState<InputOption[]>([]);

  /**
   * Function to be called when an address is selected.
   * Populates the form with the address values.
   */
  const onAddressSelect = (value: string): void => {
    placesService?.getDetails(
      { placeId: value },
      (placeDetails: google.maps.places.PlaceResult | null) => {
        const placeObj = placeDetails?.address_components;
        const addressObj = componentsToAddress(placeObj);
        /*const country =
          window.location.host != "app.vetxtend.co.nz"
            ? "Australia"
            : "New Zealand";
        addressObj.country = country;
        console.log({ addressObj }, window.location.host);*/
        // Update address field values
        for (const addressKey in addressObj) {
          const addressVal = addressObj[addressKey as keyof Address];
          form.handleChange(addressKey)(addressVal);
        }
      }
    );
  };

  useEffect(() => {
    getPlacePredictions({
      input: addressQuery,
      componentRestrictions: { country: ["au", "nz"] },
    });
    setPredictions(
      placePredictions.map((place) => ({
        value: place.place_id,
        label: place.description,
      }))
    );
    console.log({ addressQuery });
  }, [addressQuery]);

  useEffect(() => {
    console.log(`initiate`);
    onAddressSelect("a");
  }, []);

  return (
    <>
      <DropdownInput
        label="Search Address"
        value=""
        options={predictions}
        onQueryChange={setAddressQuery}
        onChange={onAddressSelect}
        disableFilter
      />
      <Row>
        <TextInput
          label="Street Address"
          value={form.values.address1}
          error={form.errors.address1}
          touched={form.touched.address1}
          onChange={form.handleChange("address1")}
          required
        />
        <TextInput
          label="Unit Number"
          value={form.values.address2}
          error={form.errors.address2}
          touched={form.touched.address2}
          onChange={form.handleChange("address2")}
        />
      </Row>
      <Row>
        <TextInput
          label="City"
          value={form.values.city}
          error={form.errors.city}
          touched={form.touched.city}
          onChange={form.handleChange("city")}
          required
        />
        <TextInput
          label="State"
          value={form.values.state}
          error={form.errors.state}
          touched={form.touched.state}
          onChange={form.handleChange("state")}
          required
        />
      </Row>
      <Row>
        <TextInput
          label="Country"
          value={form.values.country}
          error={form.errors.country}
          touched={form.touched.country}
          onChange={form.handleChange("country")}
          required
        />
        <TextInput
          label="Post Code"
          value={form.values.postcode}
          error={form.errors.postcode}
          touched={form.touched.postcode}
          onChange={form.handleChange("postcode")}
          required
        />
      </Row>
    </>
  );
};

export default AddressInput;
