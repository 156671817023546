import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import axios from "./axios";
const API = process.env.REACT_APP_BACKEND_API;

export const uploadInvoice = async (
  file: File
): Promise<AxiosResponse | undefined> => {
  const formData = new FormData();
  formData.append("file", file);

  let response;
  try {
    response = await axios({
      url: `${API}/upload/invoice`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });
  } catch (e) {
    toast.error("Failed to upload image.");
  }

  if (response && response.status === 200) return response;
};

export const uploadImage = async (
  image: File
): Promise<AxiosResponse | undefined> => {
  const formData = new FormData();
  formData.append("image", image);

  const response = await axios({
    url: `${API}/upload/image`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  }).catch((e) => {
    toast.error("Failed to upload image.");
  });

  if (response && response.status === 200) return response;
};

export const uploadPrescriptionVetXtend = async (
  file: File
): Promise<AxiosResponse | undefined> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios({
    url: `${API}/upload/prescription`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  }).catch((e) => {
    toast.error("Failed to upload image.");
  });
  if (response && response.status === 200) return response;
};

/*
 * Upon uploading the image prescription it will also send the email to
 * pharmacy email, which is done thru the backend
 * COMING FROM Old Mobile App
 */
export const uploadPrescription = async (
  image: File,
  email: string
): Promise<AxiosResponse | undefined> => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("email", email);

  const uploadScript = await axios({
    url: `${API}/upload/image`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  }).catch((e) => {
    toast.error("Failed to upload image.");
  });

  if (uploadScript && uploadScript?.status === 200) {
    const blobName = uploadScript?.data?.blobName;
    const fileSource =
      process.env.REACT_APP_BACKEND_API! +
      process.env.REACT_APP_BACKEND_IMAGE_ENDPOINT! +
      blobName;
    const sendMessage = await axios({
      url: `${API}/upload/prescription-medication`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: { email, fileSource },
    }).catch((e) => {
      toast.error("Failed to send email.");
    });
    console.log("sendMessage--", sendMessage);
    if (sendMessage && sendMessage.status === 200) return uploadScript;
  }
};
